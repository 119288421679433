import React from 'react';

import MusiciansAideImg from '../../images/MusiciansAide.png';
import MusiciansAideReflect from '../../images/musiciansAideReflect.png';

import AppProjects from '../Project_template/App-projects';
import AppMarketing from '../Project_template/App-marketing';

import ReactGA from 'react-ga';

class MusiciansAide extends React.Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return(
            <AppProjects
                pageName="Android Metronome"
                projectName="Musicians Aide"
                description="Your personal practice assistant - Made by musicians, for musicians"
                keywords="Android, Metronome, Musicians Aide, Programmable Metronome, Android Native, Android Oboe, Low Latency Audio, High Performance Audio"
                imgUrl={MusiciansAideImg}
                imgAlt="application screenshot"
                extraSectionHeading="Engineering &amp; Design"
                extraSectionContent={
                    <p>
                        After initially meeting with my colleague to discuss the product and user experience, I took over 
                        the design and development of the app. I have shipped an initial version of the app on the Google Play
                        Store, and am continuing to update it with improvements and new features.
                    </p>
                }
                quickOverviewContent={
                    <ul>
                        <li>Android SDK</li>
                        <li>Java</li>
                        <li>C++ (Android NDK)</li>
                        <li>Low Latency Audio</li>
                        <li>MVVM object model</li>
                        <li><a className="link" href="https://www.musicians-aide.app">https://www.musicians-aide.app</a></li>
                    </ul>
                }
                aboutParagraphOne={
                    <p>
                        A colleague of mine in college came to me with an idea for a metronome application. 
                        Having a background in both music and software, I was eager to tackle the challenge.
                        Our goal was to eventually transcend the simplistic metronome that reflects most apps on the market.
                    </p>
                }
                aboutParagraphTwo={
                    <p>
                        We wanted to be able to achieve complete programmability of a metronome, where you can go through
                        an entire piece without changing the metronome every meter change. To achieve this, we needed a solid
                        metronome foundation that we can also build on top of with innovative features.
                    </p>
                }
            ></AppProjects>
            
        );
    }
    
}

export default MusiciansAide;