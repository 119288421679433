import React from 'react';

import { Link } from 'react-router-dom';
import './Mobile-menu.scss';

import { CSSTransition } from 'react-transition-group';

class MobileMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showMenu: this.props.showMenu,
        };

        this.showMenu = this.showMenu.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
    }

    componentDidMount() {
        this.props.onRef(this);
    }

    componentWillUnmount() {
        this.props.onRef(undefined);
    }

    // This is called from the parent class.  
    // This toggles the display state, then listens for other 'outside' clicks that would close the menu.
    showMenu(event) {
        this.setState({ showMenu: true }, () => {
            document.addEventListener('click', this.closeMenu);
            window.addEventListener('scroll', this.closeMenu);
        });
    };

    // Once an 'outside' click is detected, it closes the menu (toggles state), then removes the listener.
    closeMenu(event) {
        this.setState({ showMenu: false }, () => {
            document.removeEventListener('click', this.closeMenu);
            window.removeEventListener('scroll', this.closeMenu);
        });
    }

    // Replace the if condition bellow.  The library will take care of
    //      mounting and unmounting the component (with animation).
    render() {
        return (

            <CSSTransition
                in={this.state.showMenu}
                timeout={300}
                classNames="showMenu"
                unmountOnExit
            >
                
                <div className="Mobile-menu" 
                    ref={ (element) => { this.dropdownMenu = element; } }
                >

                    <Link to="/RLTile" className="Nav-item Nav-link Nav-desktop fill-height">R&amp;LTile</Link>
                    <Link to="/MusiciansAide" className="Nav-item Nav-link Nav-desktop fill-height">Musicians Aide</Link>
                    <Link to="/About" className="Nav-item Nav-link Nav-desktop fill-height">About Me</Link>
                </div>
                
            </CSSTransition>
        );
    }
}

export default MobileMenu;