import React from 'react';
import Navigation from './components/Navigation/Navigation';
import AppRouting from './components/Navigation/AppRouting';
import './App.scss';
import { MediaContextProvider } from './components/Breakpoints/Media';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-146436927-1');

const App = () => (
  <div className="App">
    <MediaContextProvider>
      <Navigation />
            
      <AppRouting />
    </MediaContextProvider>
  </div>
);

export default App;
