import React from 'react';

import menuIcon from '../../icons/menu-white.svg';
import upArrow from '../../icons/up-arrow.svg';
import downArrow from '../../icons/down-arrow.svg';
import MobileMenu from './Mobile-menu/Mobile-menu';

import { Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import './Navigation.scss';

class Navigation extends React.Component {
    constructor() {
        super();

        this.state = {
            isMobileMenuOpen: false,
            active: false,
        }

        this.showMenu = this.showMenu.bind(this);
        this.showDropdown = this.showDropdown.bind(this);
        this.closeDropdown = this.closeDropdown.bind(this);
    }

    // When the Menu icon is clicked, the 'listener' method of our MobileMenu component is called.
    showMenu(event) {
        event.preventDefault();
        this.child.showMenu();
    }

    showDropdown(event) {
        this.setState({ active: true }, () => {
            document.addEventListener('click', this.closeDropdown);
            window.addEventListener('scroll', this.closeDropdown);
        });
    }

    // Once an 'outside' click is detected, it closes the menu (toggles state), then removes the listener.
    closeDropdown(event) {
        this.setState({ active: false }, () => {
            document.removeEventListener('click', this.closeDropdown);
            window.removeEventListener('scroll', this.closeDropdown);
        });
    }

    render() {
        return(
            <div>
                <div className="App-navigation">

                    <Container fluid className="fill-height">
                        <Row className="fill-height center">
                            <nav className="Navbar fill-height">
                                <Link to="/" className="Nav-item Nav-brand fill-height">Matthew Strom</Link>

                                <div className="Nav-item Nav-desktop fill-height" onClick={this.showDropdown}>
                                    Projects { this.state.active ? <img src={upArrow} alt="Up Arrow"/> : <img src={downArrow} alt="Down Arrow"/> }

                                    <CSSTransition
                                        in={this.state.active}
                                        timeout={300}
                                        classNames="showMenu"
                                        unmountOnExit
                                    >
                                        <div className="dropdown-content">
                                            <ul>
                                                <li><Link to="/MusiciansAide">Musicians Aide</Link></li>
                                                <li><Link to="/RLTile">R&amp;LTile</Link></li>
                                            </ul>
                                        </div>
                                        
                                    </CSSTransition>
                                </div>

                                <Link to="/About" className="Nav-item Nav-link Nav-desktop fill-height">About Me</Link>

                                <div className="Nav-icon Nav-mobile fill-height" onClick={this.showMenu}>
                                    <img src={menuIcon} alt="Hamburger Icon"></img>
                                </div>
                            </nav>
                        </Row>
                    </Container>

                </div>

                <MobileMenu onRef={ref => (this.child = ref)} showMenu={this.isMobileMenuOpen}/>
            </div>
        );
    }
}

export default Navigation;