import React from 'react';
import ContentSection from '../../components/ContentSection/ContentSection';

import profilePic from '../../images/profile_pic.png';
import RLTile from '../../images/rltile.png';
import MusiciansAide from '../../images/MusiciansAide.png';
import Code from '../../images/Code.png';
import './Home.scss';
import ScrollToTopOnMount from '../../components/Navigation/ScrollToTopOnMount';
import { Helmet } from 'react-helmet';

import ReactGA from 'react-ga';

class Home extends React.Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return(
            <div className="App-home">
                <Helmet>
                    <title>Matthew Strom - Software Developer &amp; Engineer</title>
                    <meta name="description" content="Matthew Strom - Overview of projects worked on"/>
                    <meta name="robots" content="index, follow"/>
                </Helmet>
    
                <ScrollToTopOnMount />
    
                <div className="App-header">
                    <div className="heading-photo">
                        <img src={profilePic} className="home-profile-pic" alt="profile" />
                    </div>
                    <div className="heading">
                        <h1>Matthew Strom</h1>
    
                        <h4 className="subHeading">Software Engineer &amp; Web Developer</h4>
                        
                        <h2 className="top-pad bot-pad mission">Building better user experiences</h2>
                    </div>
                </div>
    
                <ContentSection 
                    imgUrl={RLTile}
                    imgAlt="R&amp;L Tile"
                    heading="R&amp;L Tile - Webmaster"
                    content={
                        <ul>
                            <li>Designing custom, tailored experiences</li>
                            <li>Building modern, responsive websites</li>
                            <li>Maximizing effectiveness with SEO and Google Analytics</li>
                        </ul>
                    }
                    route="/RLTile"
                
                ></ContentSection>
    
                <ContentSection 
                    flipped="true"
                    imgUrl={MusiciansAide}
                    imgAlt="Musicians Aide"
                    heading="Musicians Aide - App Engineer"
                    content={
                        <ul>
                            <li>Creating amazing simple user interfaces</li>
                            <li>Revolutionizing the metronome</li>
                            <li>Engineering robust solutions to complex problems</li>
                        </ul>
                    }
                    route="/MusiciansAide"
                
                ></ContentSection>
    
                <ContentSection
                    imgUrl={Code}
                    imgAlt="Hello, World! Code snippet"
                    heading="Technology Explorer"
                    content={
                        <ul>
                            <li>This portfolio was built using React!</li>
                            <li>Curiosity made the cat smarter (hopefully)</li>
                            <li>Learning languages in-depth from college experiences</li>
                        </ul>
                    }
                
                ></ContentSection>
    
            </div>
        );
    }
}

export default Home;