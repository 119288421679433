import React from 'react';
import { Link } from 'react-router-dom';
import Image from './Image/Image';
import Text from './Text/Text';
import throttle from 'lodash/throttle';
import './ContentSection.scss';

import { Container, Row, Col } from 'react-bootstrap';
import { Media } from "../Breakpoints/Media"

class ContentSection extends React.Component {

    render() {
        const flipped = this.props.flipped;
        const imgUrl = this.props.imgUrl;
        const imgAlt = this.props.imgAlt;
        const heading = this.props.heading;
        const content = this.props.content;
        const route = this.props.route;


        return (
            <div className={"ContentSection" + (this.props.flipped ? ' bg2' : '')}>
                { (this.props.flipped) ? (
                    <Container className="no-gutter">
                        <Row>
                            <Col xs={12}>
                                <Media lessThan="lg"><Image imgUrl={imgUrl} imgAlt={imgAlt} className="contentImage" /></Media>
                            </Col>

                            <Col lg={8}>
                                <Text>
                                    <h1>{heading}</h1>
                                    {content}

                                    { (route) ? <Link className="link" to={route}>Read More!</Link> : null }
                                </Text>
                            </Col>

                            
                            <Col lg={4}>
                                <Media greaterThanOrEqual="lg"><Image imgUrl={imgUrl} imgAlt={imgAlt} className="contentImage" /></Media>
                            </Col>
                        </Row>
                    </Container>
                    
                ) : (
                    <Container className="no-gutter">
                        <Row>
                            <Col lg={4}>
                                <Image imgUrl={imgUrl} imgAlt={imgAlt} className="contentImage" />
                            </Col>

                            <Col lg={8}>
                                <Text>
                                    <h1>{heading}</h1>
                                    {content}

                                    { (route) ? (<Link className="link" to={route}>Read More!</Link>) : null }
                                </Text>
                            </Col>
                        </Row>
                    </Container>
                )}
            </div>
        )
    }
}

export default ContentSection;