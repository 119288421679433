import React from 'react';
import { Form, Button } from 'react-bootstrap';
import { tsConstructorType } from '@babel/types';

class ContactForm extends React.Component {
    constructor(props) {
        super(props);
        this.email = React.createRef();
        this.selectItem = React.createRef();
        this.emailBody = React.createRef();
        this.state = {
            // isEmailValidated: false,
            // isSelectValidated: false,
            // isBodyValidated: false,
            isFormValidated: false,
        }
    }

    setValidated = () => { 
        // if (this.state.isEmailValidated && 
        //     this.state.isSelectValidated && 
        //     this.state.isBodyValidated) {

            this.setState({validated: true}); 
        // }

    }

    validateEmail = email => {
        var regex = /\S+@\S+\.\S+/;
        // console.log("Email " + email + " is valid?: " + regex.test(email));
        return regex.test(email);
    }

    validateSelect = () => {
        return this.selectItem.value != "Select the correspondence";
    }

    handleSubmit = event => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if (this.validateEmail(this.email.current.value) && this.validateSelect()) {
            this.setValidated(true);
            console.log("Email: " + this.email.current.value);
            console.log("Type: " + this.selectItem.current.value);
            console.log("Body: " + this.emailBody.value);
        }
    }

    handleSelect = (event) => {
        console.log('[handleSelect]', this.selectItem);
        (this.selectItem.value != "Select the correspondence") ? this.setValidated(true) : this.setValidated(false);
    }

    render() {
        return(
            <Form validated={this.state.validated} onSubmit={this.handleSubmit}>
                <Form.Group controlId="formEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" ref={this.email} required/>
                    <Form.Text className="text-muted">
                    We'll won't share your email or send you spam.
                    </Form.Text>
                </Form.Group>
    
                <Form.Group controlId="formsSubjectSelect">
                    <Form.Label>Email Subject Heading</Form.Label>
                    <Form.Control 
                        as="select" 
                        required 
                        defaultValue="" 
                        onChange={this.handleSelect.bind(this)}
                        ref={this.selectItem}>

                        <option value="" disabled>Select the correspondence</option>
                        <option value="Musicians Aide - suggestions">Business Inquiries</option>
                        <option value="Recruiting Inquiries">Recruiting Inquiries</option>
                        <option value="All Other Inquiries">All Other Inquiries</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="formEmailBody">
                    <Form.Label>Email Body</Form.Label>
                    <Form.Control as="textarea" required  ref={(emailBody) => {this.emailBody = emailBody}}/>
                </Form.Group>
                <Button variant="primary" type="submit">
                    Send
                </Button>
            </Form>
        );
    }
}

export default ContactForm;