import React from 'react';
import ReactMarkdown from 'react-markdown';
import {Row, Col, Table} from 'react-bootstrap';
import { format } from 'date-fns';
import Text from '../../ContentSection/Text/Text';
import { Media } from "../../Breakpoints/Media";
import './ChangeLog.scss';

class ChangeLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firebase: props.firebase,
            changes: []
        }
    }

    componentDidMount() {
        const changeLogsRef = firebase.database().ref('flamelink/environments/production/content/changeLogs/en-US');
        changeLogsRef.once('value', (snapshot) => {
            snapshot.forEach(item => {
                this.setState({changes: this.state.changes.concat(item.val())});
            });
        });
    }

    render() {
        var items = [];
        var itemPills = [];
        var date;

        if (this.state.changes.length > 0) {
            for (var key in Object.keys(this.state.changes)) {
                date = format(new Date(this.state.changes[key].changeLog.date), 'MMM d, YYY');

                items.push(
                    <tr key={this.state.changes[key].id}>
                        <td><Text smallPadding="true"><h4>{this.state.changes[key].changeLog.releaseVersion}</h4></Text></td>
                        <td><Text smallPadding="true"><h4>{date}</h4></Text></td>
                        <td><Text smallPadding="true"><ReactMarkdown>{this.state.changes[key].changeLog.releaseNotes}</ReactMarkdown></Text></td>
                    </tr>
                );

                itemPills.push(
                    <div className="content-card" key={this.state.changes[key].id}>
                        <Row className="pill-header">
                            <Col xs={4} lg={2}><Text smallPadding="true"><h4>{this.state.changes[key].changeLog.releaseVersion}</h4></Text></Col>
                            <Col xs={8} lg={2}><Text smallPadding="true"><h4>{date}</h4></Text></Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Text><ReactMarkdown>{this.state.changes[key].changeLog.releaseNotes}</ReactMarkdown></Text>
                            </Col>
                        </Row>
                    </div>
                )
            }
        }

        // TODO: On mobile, use layout similar to In Progress

        return (
            <div className="content-container">
                {(items.length > 0) ? (
                    <Row>
                        <Media greaterThanOrEqual="lg"><Col>
                            <Table striped bordered variant="dark">
                                <thead>
                                    <tr>
                                        <th>Version</th>
                                        <th>Date</th>
                                        <th>Release Notes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items}
                                </tbody>
                            </Table>
                        </Col></Media>
                        <Media lessThan="lg"><Col>
                            <div className="content-container">
                                {(items.length > 0) ? (
                                    itemPills
                                ) : null}
                            </div>
                        </Col></Media>
                    </Row>
                ) : null}
            </div>
        )
    }
}

export default ChangeLog;