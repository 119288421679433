import React from 'react';

import { Container, Row, Col } from 'react-bootstrap';

import ProfilePic from '../../images/Exit38Selfie.png';
import MasonLake from '../../images/MasonLake.jpg';
import MattVantage from '../../images/Matt_Vantage.jpg';

import './About.scss';
import Text from '../../components/ContentSection/Text/Text';
import Image from '../../components/ContentSection/Image/Image';
import ContactForm from '../../components/ContentSection/ContactForm/ContactForm';

import ScrollToTopOnMount from '../../components/Navigation/ScrollToTopOnMount';
import { Helmet } from "react-helmet";

import ReactGA from 'react-ga';

class About extends React.Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return(
            <div className="About">
    
                <Helmet>
                    <title>Matthew Strom - About Me</title>
                    <meta name="description" content="A quick biography about myself and my passions"/>
                    <meta name="robots" content="index, follow"/>
                </Helmet>
    
                <ScrollToTopOnMount />
    
                <div className="About-header heading-photo">
                    <img src={ProfilePic} alt="Profile" className="About-profile-pic"></img>
    
                    <div className="heading">
                        <h1>About Me</h1>
                    </div>
                </div>
    
                <div className="About-content">
                    <Container className="no-gutter">
                        <Row>
                            <Col xs={12} lg={4}>
                                <Text smallPadding="true">
                                    I am a Software Developer who hails from the midwest and is currently
                                    experiencing all that Seattle has to offer.  I started my
                                    software journey at the University of Wisconsin - Eau Claire, where I was
                                    able to get some fantastic learning experiences from top tier teachers and 
                                    industry leaders.
                                </Text>
                            </Col>
    
                            <Col xs={12} lg={4}>
                                <Image imgUrl={MasonLake} imgAlt="Mason Lake" />
                            </Col>
    
                            <Col xs={12} lg={4}>
                                <Text smallPadding="true">
                                    After college I continued my developing experiences with a local company in Eau Claire 
                                    for another year as a Web Developer.  Since then I have had the opportunity to work with 
                                    local companies to redesign websites, and engineer solutions for my own personal projects.
                                </Text>
                            </Col>
    
                            <Col xs={12} lg={4}>
                                <Text smallPadding="true">
                                    If I'm not outdoors, I'm inside trying to better myself as a developer and engineer.  I love to explore 
                                    different technologies, and tackle complicated problems.  I Believe it's important for developers to 
                                    explore new technologies, as that knowledge will indefinately help you in your future projects at work or at home.
                                </Text>
                            </Col>
    
                            <Col xs={12} lg={4}>
                                <Image imgUrl={MattVantage} imgAlt="Vantage Rock Climbing" />
                            </Col>
    
                            <Col xs={12} lg={4}>
                                <Text smallPadding="true">
                                    Outside of work I am an avid outdoors enthuziast.  You may find me dangling from a rope off 
                                    of a cliff, or perhaps hiking a tall mountain peak.  I also enjoy photography, as you can see from 
                                    the wonderful images on this portfolio!
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Text smallPadding="true">
                                    <h4>Want to reach out?</h4>
                                    <p>For recruiting or other professional inquiries, please use the bellow form:</p>

                                    <ContactForm />
                                </Text>
                            </Col>
                        </Row>
                    </Container>
    
    
                </div>
            </div>
        );
    }
}

export default About;