import React from 'react'
import {Tabs, Tab} from 'react-bootstrap';

import './TabLayout.scss';
import ChangeLog from './ChangeLog/ChangeLog';
import InProgress from './In Progress/InProgress';
import Backlog from './Backlog/Backlog';

function TabLayout() {
    return(
        <Tabs defaultActiveKey="Change Log">
            <Tab eventKey="Change Log" title="Change Log">
                <ChangeLog />
            </Tab>
            <Tab eventKey="In Progress" title="In Progress">
                <InProgress />
            </Tab>
            <Tab eventKey="Backlog" title="Backlog">
                <Backlog />
            </Tab>
        </Tabs>
    )
}

export default TabLayout;

