import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Image from '../../components/ContentSection/Image/Image';

import './App-projects.scss';

import ScrollToTopOnMount from '../../components/Navigation/ScrollToTopOnMount';
import {Helmet} from "react-helmet";
import { Media } from "../../components/Breakpoints/Media"

class AppProjects extends React.Component {

    render() {
        const pageName = this.props.pageName;
        const projectName = this.props.projectName;
        const description = this.props.description;
        const keywords = this.props.keywords;
        const imgUrl = this.props.imgUrl;
        const imgAlt = this.props.imgAlt;

        const extraSectionHeading = this.props.extraSectionHeading;
        const extraSectionContent = this.props.extraSectionContent;
        const quickOverviewContent = this.props.quickOverviewContent;
        const aboutParagraphOne = this.props.aboutParagraphOne;
        const aboutParagraphTwo = this.props.aboutParagraphTwo;


        let isClient = false;

        if (typeof(window) !== 'undefined') {
            isClient = true;
        }

        return(
            <div className={"App-projects " + projectName}>

                <Helmet>
                    <title>{projectName}</title>
                    <meta name="description" content={description}/>
                    <meta name="robots" content="index, follow"/>
                    <meta name="keywords" content={keywords}/>
                </Helmet>

                <ScrollToTopOnMount />

                <Container className="projects-content no-gutter">
                    <Row className="content-row">
                        <Col className="heading">
                            <h1>{pageName}</h1>
                            {isClient ? (
                                <Media lessThan="lg">
                                    <Col xs={12} lg={4}>
                                        <Image imgUrl={imgUrl} imgAlt={imgAlt} noPadding="true" />
                                    </Col>
                                </Media>
                            ) : null }
                        </Col>
                    </Row>

                    <Row className="content-row">

                        <Col xs={12} lg={4}>
                            <div className="content-pill">
                                <h4>Quick Overview</h4>

                                {quickOverviewContent}
                            </div>
                        </Col>
                            <Col xs={12} lg={4}>
                                <Media greaterThanOrEqual="lg"><Image imgUrl={imgUrl} imgAlt={imgAlt}/></Media>
                            </Col>

                        <Media lessThan="lg">
                            <Col xs={12}>
                                <div className="content-pill">
                                    <h4>About the Project</h4>

                                    {aboutParagraphOne}

                                    {aboutParagraphTwo}
                                </div>
                            </Col>
                        </Media>
                        <Col xs={12} lg={4}>
                            <div className="content-pill">
                                <h4>{extraSectionHeading}</h4>
                                
                                {extraSectionContent}
                            </div>
                        </Col>
                    </Row>
                    
                    <Media greaterThanOrEqual="lg">
                        <Row className="content-row">
                            <Col>
                                <div className="content-pill">
                                    <h4>About the Project</h4>

                                    {aboutParagraphOne}

                                    {aboutParagraphTwo}
                                </div>
                            </Col>
                        </Row>
                    </Media>
                </Container>
            </div>
        )
    }
}

export default AppProjects;