import React from 'react';

import RLTileImg from '../../images/RL_Tile.jpg';

import AppProjects from '../Project_template/App-projects';

import ReactGA from 'react-ga';

class RLTile extends React.Component {
    componentDidMount() {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }

    render() {
        return(
            <AppProjects 
                pageName="R&amp;L Tile Co." 
                projectName="RLTile"
                description="A website rennovation project"
                keywords="Custom Website, Website Rennovation, Single Page Design, Modern Website, Responsive Website"
                imgUrl={RLTileImg}
                imgAlt="website screenshot"
                extraSectionHeading="Project Management"
                extraSectionContent={
                    <p>
                        Part of the development process required working closely with the clients to
                        create project requirements, and get approval on various features.  This includes
                        managing tasks through online task boards, and in-person meetings.
                    </p>
                }
                quickOverviewContent={
                    <ul>
                        <li>Angular 7</li>
                        <li>TypeScript</li>
                        <li>SCSS</li>
                        <li>Responsive + Modern</li>
                        <li>Simple Design</li>
                        <li><a className="link" href="https://www.rltile.com">https://www.rltile.com</a></li>
                    </ul>
                }
                aboutParagraphOne={
                    <p>
                        Being already familiar with AngularJS, this project was an excellent
                        opportunity to work with Angular (2+) and TypeScript.  It was fairly
                        simple to get started, and in no time I was up and running on development!
                    </p>
                }
                aboutParagraphTwo={
                    <p>
                        The clients wanted a simplified, modern and responsive website, so it was
                        key that the site worked great on mobile and desktop.  To achieve the best of
                        both worlds, I created two templates in order to maximize the design and viewing
                        experience on both platforms.
                    </p>
                }
            ></AppProjects>
            
        );
    }
}

export default RLTile;