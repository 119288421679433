import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Text from '../../components/ContentSection/Text/Text'
import Image from '../../components/ContentSection/Image/Image';

import './App-marketing.scss';

import ScrollToTopOnMount from '../../components/Navigation/ScrollToTopOnMount';
import {Helmet} from "react-helmet";
import { Media } from "../../components/Breakpoints/Media"
import TabLayout from "../../components/TabLayout/TabLayout"

import ReactGA from 'react-ga';

class AppMarketing extends React.Component {
    render() {
        const mediaSrc = this.props.mediaUrl;
        const mediaSrcMobile = this.props.mediaUrlMobile;
        const imgAlt = this.props.imgAlt;

        const appName = this.props.name;
        const subHeading = this.props.subHeading;
        const aboutHeading = this.props.aboutHeading;
        const about = this.props.about;
        const description = this.props.description;
        const keywords = this.props.keywords;

        const featuresName = this.props.featuresName;
        const features = this.props.features;
        const futurePlansName = this.props.futurePlansName;
        const futurePlans = this.props.futurePlans;
        const betaTestingHeader = this.props.betaTestingHeader;
        const betaTestSubHeader = this.props.betaTestSubHeader;
        const betaTesting = this.props.betaTesting;
        const contact = this.props.contact;

        let isClient = false;

        if (typeof(window) !== 'undefined') {
            isClient = true;
        }

        const handleClick = () => {
            if (isClient) {
                ReactGA.event({
                    category: "Download APK",
                    action: "User Downloaded the app"
                });
            }
        }

        return (
            <div className="AppMarketing">

                <Helmet>
                    <title>{appName}</title>
                    <meta name="description" content={description}/>
                    <meta name="robots" content="index, follow"/>
                    <meta name="keywords" content={keywords}/>
                </Helmet>

                <ScrollToTopOnMount />

                <Container className="marketing-content no-gutter">
                    <Row className="content-row">
                        {isClient ? (
                            <Media lessThan="lg">
                                <Col className="heading noBotPadding">
                                    {this.header(appName, subHeading, mediaSrcMobile, imgAlt, isClient)}
                                </Col>
                           </Media>
                        ) : null }
                        {isClient ? (
                            <Media greaterThanOrEqual="lg">
                                <Col className="heading">
                                    {this.header(appName, subHeading, mediaSrcMobile, imgAlt, isClient)}
                                </Col>
                            </Media>
                        ) : null }
                        
                    </Row>
                    <Row className="content-container">
                        <Col lg={8}>
                            <Row className="content-row">
                                <Text>
                                    <div className="content-header">
                                        <h1>{featuresName}</h1>
                                    </div>

                                    {features}
                                </Text>
                            </Row>
                            <Row className="content-row">
                                <Text>
                                    <div className="content-header">
                                        <h1>{futurePlansName}</h1>
                                    </div>

                                    {futurePlans}
                                </Text>
                            </Row>
                        </Col>
                        {isClient ? (
                            <Col className="d-none d-lg-block" lg={4}>
                                <Image imgUrl={mediaSrc} imgAlt={imgAlt}/>
                            </Col>
                        ) : null }
                    </Row>
                    <Row className="content-container">
                        <Col>
                            <Row className="content-row">
                                <Text>
                                    <div className="content-header">
                                        <h1>{aboutHeading}</h1>
                                    </div>
                                    
                                    {about}
                                </Text>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="content-container">
                        <Col>
                            <Row className="content-row">
                                <Text>
                                    <div className="content-header">
                                        <h1>{betaTestingHeader}</h1>
                                        <h4>{betaTestSubHeader}</h4>
                                    </div>

                                    {betaTesting}
                                    
                                    {contact}
                                </Text>

                                <Button className="download-btn" variant="primary" size="lg" 
                                    href={process.env.PUBLIC_PATH + "app-release.apk"} 
                                    download="Musicians-Aide.apk"
                                    onClick={isClient ? handleClick : null}>
                                        Download APK 
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <TabLayout/>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }

    header(appName, subHeading, mediaSrcMobile, imgAlt, isClient) {
        return(
            <div>
                <h1>{appName}</h1>
                <h5>{subHeading}</h5>

                {isClient ? (
                    <Media lessThan="lg">
                        <Image imgUrl={mediaSrcMobile} imgAlt={imgAlt} noBotPadding="true"/>
                    </Media>
                ) : null }
            </div>
        )
    }
}

export default AppMarketing;