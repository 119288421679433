import React from 'react';
import { Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import './AppRouting.scss';

import About from '../../pages/About_Me/About';
import Home from '../../pages/Home/Home';
import MusiciansAide from '../../pages/MusiciansAide/MusiciansAide';
import RLTile from '../../pages/RLTile/RLTile';

function AppRouting() {
    return(
        <Container className="body-padding">
            <Route exact path="/" component={Home} />
            <Route path="/MusiciansAide" component={MusiciansAide} />
            <Route path="/RLTile" component={RLTile} />
            <Route path="/About" component={About} />
        </Container>
    );
}

export default AppRouting;