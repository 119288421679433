import React from 'react';

import './Image.scss';

class Image extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            noPadding: props.noPadding,
            noBotPadding: props.noBotPadding
        };
    }


    render() {

        return(
            <img 
                src={this.props.imgUrl} 
                alt={this.props.imgAlt} 
                className={
                    "contentImage" 
                    + (this.state.noPadding ? " noPadding" : "")
                    + (this.state.noBotPadding ? " noBotPadding" : "")
                } />
        )
    }
}

export default Image;